<template>
  <div class="page-wrapper">
    <div class="page-header">
      <div class="container">
        <div class="page-header-inner">
          <div>Order ID #{{ order.id }}</div>
          <div v-if="order.status === 8" class="page-header-canceled">This order has been cancelled by customer</div>
        </div>
      </div>
    </div>
    <div class="page-main">
      <div class="order__wrapper" :class="{'is-aprove': statusAprove, 'is-decline': statusDecline}">
        <div class="order__part">
          <div class="order__customer">
            <div class="order__customer-icon">
              <div class="icon-button icon-button--round icon-button--blue">
                <i class="ri-arrow-up-circle-line"></i>
              </div>
            </div>
            <div class="order__customer-name">
              <span v-if="order.pickUpFathersName">{{ order.pickUpFathersName }}’ </span>
              <span>{{ order.pickUpFirstName }} </span>
              <span>{{ order.pickUpMiddleInitial }} </span>
              <span>{{ order.pickUpLastName }} </span>
              <span v-if="order.pickUpNickName">({{ order.pickUpNickName }})</span>
              <span>{{ order.pickUpName }} </span>
            </div>
            <div class="order__customer-address">Address: {{ order.pickUpAddress }} {{ order.pickUpCity }}</div>
          </div>
          <div class="order__customer">
            <div class="order__customer-icon">
              <div class="icon-button icon-button--round icon-button--orange">
                <i class="ri-arrow-down-circle-line"></i>
              </div>
            </div>
            <div class="order__customer-name">
              <span v-if="order.dropOffFathersName">{{ order.dropOffFathersName }}’ </span>
              <span>{{ order.dropOffFirstName }} </span>
              <span>{{ order.dropOffMiddleInitial }} </span>
              <span>{{ order.dropOffLastName }} </span>
              <span v-if="order.dropOffNickName">({{ order.dropOffNickName }})</span>
              <span>{{ order.dropOffName }} </span>
            </div>
            <div class="order__customer-address">Address: {{ order.dropOffAddress }} {{ order.dropOffCity }}</div>
          </div>
        </div>
        <div class="order__part">
          <div class="order__row">
            <div class="order__row-title">Date of delivery</div>
            <div class="order__row-subtitle">{{ moment.utc(order.routeDate).format('DD MMM YYYY') }}</div>
          </div>
          <div v-if="order.notes" class="order__row">
            <div class="order__row-title">Notes</div>
            <div class="order__row-subtitle">{{ order.notes }}</div>
          </div>
          <div v-if="order.attachmentPath" class="order__row">
            <div class="order__row-title">Attachment</div>
            <el-image :src="order.attachmentPath" :preview-src-list="[order.attachmentPath]" fit="cover">
              <div slot="error" class="order__image-error" />
            </el-image>
          </div>
        </div>
        <div class="order__part order__part--third">
          <div class="order__row">
            <div class="order__row-title">Service</div>
            <div class="order__row-subtitle">{{ order.serviceName }} – {{ order.servicePrice }} $</div>
          </div>
          <div class="order__row">
            <div class="order__row-title">Fuel Surcharge</div>
            <div class="order__row-subtitle">{{ order.fuelSurcharge }} $</div>
          </div>
          <div class="order__row">
            <div class="order__row-title">HST</div>
            <div class="order__row-subtitle">{{ order.hst }} $</div>
          </div>
          <div class="order__row">
            <div class="order__row-title">Total</div>
            <div class="order__row-subtitle">{{ order.total }} $</div>
          </div>
        </div>
        <div v-if="order.status === 0 || statusAprove" class="order__part order__buttons">
          <button
            class="button button--red order__button"
            :class="{'button--fill': statusDecline, 'button--disabled': statusAprove}"
            @click="decline"
          >
            Decline
          </button>
          <button
            class="button button--green order__button"
            :class="{'button--fill': statusAprove, 'button--disabled': statusDecline}"
            @click="approve"
          >
            <span v-if="statusAprove">Approved</span>
            <span v-else>Approve</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import moment from 'moment'

export default {
  name: 'OrderPage',
  data() {
    return {
      moment,
      statusAprove: false,
      statusDecline: false,
      fileUrl: null,
      numChar: 60
    }
  },
  computed: {
    ...mapState({
      order: s => s.orders.order
    }),
    pathID() {
      return this.$route.params.id
    }
  },
  watch: {
    async pathID(id) {
      await this.fetchOrder(id)
    }
  },
  async created() {
    const mobile = document.documentElement.clientWidth <= 480
    mobile ? (this.numChar = 30) : (this.numChar = 60)
    const orderID = this.$route.params.id
    await this.fetchOrder(orderID)
  },
  methods: {
    ...mapActions({
      fetch: 'orders/fetchAdminOrder',
      fetchOrders: 'orders/fetchAdminOrders',
      approveOrder: 'orders/approve',
      declineOrder: 'orders/decline'
    }),
    ...mapMutations({
      update: 'orders/updateOrder',
      reset: 'orders/reset'
    }),
    async fetchOrder(id) {
      await this.fetch(id)
    },
    async approve() {
      if (this.statusAprove) return
      const req = await this.approveOrder(this.order.id)
      if (req.data.result) {
        this.statusAprove = true
        this.reset()
        this.fetchOrders()
      }
    },
    async decline() {
      if (this.statusDecline) return
      const req = await this.declineOrder(this.order.id)
      if (req.data.result) {
        this.statusDecline = true
        this.reset()
        this.fetchOrders()
      }
    },
    updateOrder(order) {
      this.update(order)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
<style lang="scss">
@import 'src/assets/styles/_variables.scss';

.order__image-error {
  background-color: $grey_border_light;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
